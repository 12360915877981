export default `<div><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="width:36pt; display:inline-block">&nbsp;</span><span style="width:36pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold">Terms of Use</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">These terms and the other documents expressly incorporated by reference in the document (collectively, these "</span><span style="font-family:Times-Bold; font-weight:bold">Terms of Use</span><span style="font-family:Times-Roman">") govern use and access to the Hosted Service and Data. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">The
Hosted Service and Data is offered and available to users who are 21 
years of age or older. Additionally, You may not use the Hosted Service 
or Data if any applicable law or regulation prohibits such use.&nbsp; 
When You use the Hosted Service and Data, You represent and warrant that
You meet the foregoing eligibility requirements and are of legal age to
form a binding contract with Grodan.&nbsp; If You are accessing the 
Hosted Service or Data on behalf of a legal entity, You warrant that You
are authorized to enter into and bind such entity to these Terms of 
Use.&nbsp; </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">1. </span><span style="width:24pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold">Definitions.</span><span style="font-family:Times-Roman; letter-spacing:-0.15pt"> </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="width:36pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">“</span><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">Affiliate(s)”</span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">
means any person, firm, trust, partnership, corporation, company or 
other entity or combination thereof, which directly or indirectly, 
Controls the subject entity, is Controlled by the subject entity, or is 
under common Control with the subject entity. “Control,” for purposes of
this definition, means direct or indirect ownership of more than fifty 
percent (50%) of the voting interests of the subject entity. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">“</span><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">Aggregate Data</span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">”
means all anonymous, aggregated content, data, statistics, and other 
information generated by the Hosted Service which may include 
Contributions, provided that no such information will directly identify 
and cannot reasonably be used to identify You or Your Users. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">“Contributions” </span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">has the meaning ascribed to it in Section 3.3.1. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">“</span><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">Data</span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">”
means information obtained by Grodan from publicly available sources or
its third party content providers and made available to You through the
Hosted Service or pursuant to an Order Form, as more fully described in
the Documentation.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">“</span><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">Documentation</span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">”
means Our then current guides and manuals describing the use and 
operation of the Hosted Service and Data that We publish and make 
generally available for the Hosted Service. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt"> “</span><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">Hosted Service</span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">”
means the generally available Web-based, on-line, hosted software 
listed in an Order Form including, without limitation, all corrections, 
updates, modifications, releases, versions, and enhancements to such 
software that may hereafter be generally released by Us, but excluding 
Data. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">“</span><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">Limited License</span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">” has the meaning ascribed to it in Section 2.1.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">“</span><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">Malicious Code</span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">”
means viruses, worms, time bombs, Trojan horses, malware, and other 
harmful or malicious code, files, scripts, agents or programs.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">“</span><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">Order Form</span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">”
means the ordering documents for Your purchases from Us that both 
Parties execute from time to time. All Order Forms shall be deemed 
incorporated herein and will be attached as Schedule 1 to the Agreement.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">“Professional Services” </span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">means implementation and configuration services that may be provided to You and set forth in the applicable Order Form.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">“</span><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">Start Date</span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">” means the date specified in an Order Form as the start date of Your Subscription Term. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">“</span><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">Subscription Term</span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">”
means the period of time that You may use and access the Hosted Service
beginning on the Start Date and as set forth in the applicable Order 
Form. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">“</span><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">Users</span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">”
means individuals who are authorized by You to use the Hosted Service, 
for whom subscriptions to the Hosted Service have been purchased, and 
who have been supplied user identifications and passwords by You (or by 
Us at Your request). Users may include but are not limited to Your 
employees, consultants, contractors and agents, Affiliates, or third 
parties with whom You transact business. User subscriptions are for 
designated Users and cannot be shared or used by more than one User, but
may be reassigned to new Users replacing former Users who no longer 
require ongoing use of the Hosted Service. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">“</span><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">User Account</span><span style="font-family:Times-Roman; letter-spacing:-0.15pt">” has the meaning ascribed to it in Section 3.4.1.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman; letter-spacing:-0.15pt">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">2.</span><span style="width:27pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold">Grant of License.</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">2.1</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Limited License to Use the Hosted Service.</span><span style="font-family:Times-Roman">&nbsp;
If You meet the eligibility requirements for use above,&nbsp; You are 
granted by Grodan a non-exclusive, non-sublicensable and 
non-transferable license during the Subscription Term ("</span><span style="font-family:Times-Bold; font-weight:bold">Limited License</span><span style="font-family:Times-Roman">")
to (a) access and use the Hosted Service and Data via the internet and 
(b) use the Documentation.&nbsp; This Limited License does not limit 
Grodan's ability to: (i) discontinue the Hosted Service and Data, 
temporarily or permanently; (ii) make procedural and/or technical 
alterations and/or improvements to the Hosted Service, Data and 
Documentation; (iii) change or remove data or information; (iii) 
restrict the use of the Hosted Service and Data or certain 
functionalities; and/or (iv) deny the User access or use of the Hosted 
Service and Data in full or in part, temporarily or permanently or 
terminate the Limited License. Your purchase of User subscription(s) for
the Hosted Service is neither contingent upon the delivery of any 
future functionality or features, nor dependent upon any oral or written
public comments made by Grodan with respect to future functionality or 
features. </span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">2.2</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Restrictions</span><span style="font-family:Times-Roman">.
The Limited License granted in Section 2.1 is conditioned upon Your 
compliance with the terms and conditions of the Agreement. You may use 
the Hosted Service and Data solely for Your own internal business 
purposes, in compliance with applicable law, and shall not: (a) permit 
any third party to access the Hosted Service or Data except as permitted
herein or in an Order Form, (b) license, sublicense, sell, resell, 
rent, lease, transfer, distribute, use the Hosted Service or Data for 
commercial time sharing, outsourcing or otherwise commercially exploit 
the Hosted Service or Data; (c) create derivative works based on the 
Hosted Service or Data; (d) modify, reverse engineer, translate, 
disassemble, or decompile the Hosted Service or Data, or cause or permit
others to do so; (e) copy, frame or mirror any content forming part of 
the Hosted Service or Data, other than on Your own intranets or 
otherwise for Your own internal business purposes; (f) access the Hosted
Service or Data in order to (i) build a competitive product or service,
or (ii) copy any ideas, features, functions or graphics of the Hosted 
Service or Data; and (g) remove any title, trademark, copyright and/or 
restricted rights notices or labels from the Hosted Service, Data or 
Documentation.</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">2.3</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Reserved Rights.</span><span style="font-family:Times-Roman">
We hereby reserve all right, title and interest in and to the Hosted 
Service and Data not expressly granted in this Agreement. Nothing in 
this Agreement shall limit in any way Our right to develop, use, 
license, create derivative works of, or otherwise exploit the Hosted 
Service or Data or to permit third parties to do so.</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">2.4</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Termination for Violation</span><span style="font-family:Times-Roman">.
Violation of the provisions in the Agreement will cause termination of 
the Limited License. The Limited License may need to be terminated for 
some other reason, or for no reason, with or without cause, and Grodan 
reserves the right to perform such a termination.&nbsp; The Limited 
License does not grant You intellectual property rights in or in 
relation to the Hosted Service or the content of the Hosted Service. </span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">3. </span><span style="width:24pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold">Use of the Hosted Service</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">3.1 </span><span style="width:18pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Accessing the Hosted Service. </span><span style="font-family:Times-Roman">Each
access to the Hosted Service and Data is Your risk and responsibility. 
We may without notice withdraw or amend the Hosted Service or Data, or 
the content or material we provide on the Hosted Service, at Our sole 
discretion.&nbsp; Maintenance, adjustment, or other factors beyond Our 
control may cause disruptions in the Hosted Service.&nbsp; We will not 
be liable if for any reason all or any part of the Hosted Service or 
Data is unavailable at any time. From time to time, we may restrict 
access to some parts of the Hosted Service or Data, or the entire Hosted
Service or Data, to users, including registered Users.</span><span style="font-family:Times-Roman">&nbsp; </span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">3.2</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Responsibility and Restrictions.</span><span style="font-family:Times-Roman">
You are responsible for making all arrangements necessary for You to 
have access to the Hosted Service and Data and ensuring that all persons
who access the Hosted Service and Data through Your access connection 
are aware of these Terms of Use and comply with them. </span><span style="font-family:Times-Roman">In
order to be able to use the Hosted Service and Data, the User shall 
provide for any necessary connection to access the Hosted Service and 
Data. A connection includes buts is not limited to the necessary 
equipment, system software and (internet) connection. Grodan is not 
responsible for any connection errors made by the connection service 
providers of the User. You shall be responsible for Users’ compliance 
with the Agreement and for Your Contribution(s)(defined below). You 
shall not (i) use the Hosted Service or Data to store or transmit 
infringing, libelous, or otherwise unlawful or tortious material, or to 
store or transmit material in violation of third party privacy rights, 
(ii) use the Hosted Service or Data to store or transmit Malicious Code,
(iii) interfere with or disrupt the integrity or performance of the 
Hosted Service or Data, or (iv) attempt to gain unauthorized access to 
the Hosted Service or Data or its related systems or networks. Any 
conduct by You that in Our discretion restricts or inhibits any of Our 
other customers from using or enjoying the Hosted Service or Data is 
expressly prohibited. You will use commercially reasonable efforts to 
prevent unauthorized access to, or use of, the Hosted Service or Data, 
and notify Us promptly of any such unauthorized access or use. </span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">3.3</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">User Contributions</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">3.3.1</span><span style="width:12pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman">If You provide information, data and/or other content ("</span><span style="font-family:Times-Bold; font-weight:bold">Contribution(s)")</span><span style="font-family:Times-Roman">
through the Hosted Service, You represent, warrant and guarantee that 
the You are the rightful owner or have all rights necessary to provide 
such Contribution.&nbsp; The Hosted Service shall only function in full 
when Contributions are supplied and the Contributions supplied are 
complete, correct and up-to-date. </span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">3.3.2</span><span style="width:12pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman">If
You make a Contribution to the Hosted Service, You understand and agree
that Grodan has a&nbsp; non-exclusive, perpetual, free of charge, 
sublicensable and transferable (including without limitation of Grodan’s
Affiliates) license to use Contributions, including without limitation 
that Grodan is entitled to use and share Aggregate Data, including, 
without limitation: (i) to enable the User to use the Hosted Service and
Data; (ii) to provide and improve the Hosted Service and Data; and/or 
(iii) for updating and/or further developing the Hosted Service and 
Data. The User recognizes and acknowledges that the Contribution may be 
transferred to and used by third parties engaged by Grodan, including 
without limitation Grodan's cloud or other service providers, in order 
to operate and/or manage the Hosted Service.</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">3.3.3</span><span style="width:12pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman">Grodan</span><span style="font-family:Times-Roman">
is not liable for, including for the accuracy of, any Contributions, 
Confidential Information and any other materials or communications that 
the User or others post or provides through the Hosted Service. Grodan 
reserves the right to (announced or unannounced) remove any 
Contributions or any other materials or statements that have been placed
through the Hosted Service. </span></p><p style="margin-top:12pt; margin-bottom:0pt; widows:0; orphans:0; font-size:10pt"><span style="font-family:Verdana">3.3.4</span><span style="width:9.65pt; display:inline-block">&nbsp;</span><span style="font-family:Verdana">If
You receive notice that Contributions must be removed, modified and/or 
disabled to avoid violating applicable law or third party rights You 
will promptly do so. If You do not take required action in accordance 
with the above, or if in Our judgment continued violation is likely to 
reoccur, We may disable the applicable Hosted Service. Upon Our request,
You shall confirm such deletion and discontinuance of use in writing 
and We shall be authorized to provide a copy of such confirmation to any
such third party claimant or governmental authority, as applicable. In 
addition, if We are required by any third party rights holder to remove 
content, or receive information that content provided to You may violate
applicable law or third party rights, We may discontinue Your access to
content including but not limited to Data through the Hosted Service. </span></p><p style="margin-top:0pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">3.4</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">User Account and Registration</span><span style="font-family:Times-Roman"> </span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">3.4.1</span><span style="width:12pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman">In order to access certain features on the Hosted Service, You may be required or permitted to create a user account (a “</span><span style="font-family:Times-Bold; font-weight:bold">User Account</span><span style="font-family:Times-Roman">”).
If You choose to create such a User Account, You are responsible for 
keeping Your password a secret. By using the Hosted Service You take 
full responsibility for Your actions relating to Your use of the Hosted 
Service and Data.&nbsp; If You choose or are provided with a user 
identification code or password or any other piece of information as 
part of Grodan's security procedures, You must treat that information as
confidential and not disclose it to any third party. You agree to 
notify Us immediately if You suspect or learn of any unauthorized use of
Your User Account.</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">3.4.2</span><span style="width:12pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman">To
access the Hosted Service or Data or some of the resources it offers, 
You may be asked to provide certain registration details or other 
information. It is a condition of Your use of the Hosted Service and 
Data that all the information You provide on the Hosted Service is 
correct, current, and complete. </span><span style="font-family:Times-Roman">In
case of any changes of registration details or other relevant data, the
User shall update such data through the Hosted Service. </span><span style="font-family:Times-Roman">You
agree that all information You provide to register with this Hosted 
Service or otherwise, including, but not limited to, through the use of 
any interactive features on the Hosted Service, is governed by Our 
Affiliate's </span><span style="font-family:Times-Roman">Privacy Statement</span><span style="font-family:Times-Roman">
at https://www.grodan.com/privacy-statement/ hereby incorporated into 
this Agreement. A current version of the Privacy Statement is attached 
hereto as Schedule 3 and is subject to change from time to time. You 
consent to all actions We take with respect to Your information 
consistent with such </span><span style="font-family:Times-Roman">Privacy Statement</span><span style="font-family:Times-Roman">. </span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">4.</span><span style="width:27pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold; letter-spacing:-0.15pt">Fees</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="width:36pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman">4.1</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Subscription Fees</span><span style="font-family:Times-Roman">. </span><span style="font-family:Times-Roman">You
shall pay Us fees in accordance with the amounts and dates specified in
the applicable Order Form. Except as otherwise provided: (i) the 
subscription fees set forth in each Order Form hereunder shall be fixed 
during the Subscription Term of such Order Form, including for purchases
of additional Users; (ii) the Subscription Term and services fees set 
forth in each Order Form hereunder will be invoiced upon execution of 
such Order Form. Except as otherwise specified herein, fees are based on
services purchased and not actual usage, payment obligations are 
non-cancelable, payment terms are quoted from the date of invoice and 
fees paid are non-refundable.</span></p><p style="margin-top:12pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">4.2</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Adjustments and Late Payments.</span><span style="font-family:Times-Roman">&nbsp;
In the event a price increase must occur, Grodan may only raise its 
prices once per year.&nbsp; In addition to a once annual price increase,
Grodan is entitled to adjust the prices, if cost-determining factors, 
including taxes, excise duties, import duties, exchange rates, wages, 
the prices of goods and/or services (whether or not obtained from third 
parties by Grodan), change after the offer and/or the conclusion of a 
contract. Use of the Hosted Service and Data is contingent on fulfilled 
payments obligations.&nbsp; If a payment is late, an interest payment of
1% per month (equivalent to 12% per annum) on the outstanding amount, 
calculated from the due date up to and including the day of payment, 
where part of a month is regarded as a full month, is payable on the 
invoice amount by the User without further notice of default. You shall 
reimburse Us for all reasonable, actual costs (including reasonable 
attorneys’ fees) incurred by Us in the collection of overdue amounts.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">4.3</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Taxes</span><span style="font-family:Times-Roman">.
If We have the legal obligation to pay or collect taxes for which You 
are responsible, including but not limited to, sales, use, transfer, 
privilege, excise, and all other taxes and duties that are levied or 
imposed by reason of Our performance under this Agreement, the 
appropriate amount shall be invoiced to and paid by You, unless You 
provide Us with a valid tax exemption certificate authorized by the 
appropriate taxing authority.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">4.4</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Suspension of Services</span><span style="font-family:Times-Roman">.
If any amount owed by You is thirty (30) days or more overdue, We may, 
with seven (7) days’ prior notice to You, without limiting Our other 
rights and remedies, suspend Hosted Service and access to Data until You
pay such amounts owed. We shall not exercise Our rights under this 
Section 4.4 if the applicable charges are under reasonable and 
good-faith dispute and You are cooperating diligently to resolve the 
dispute. If You believe, in good faith, that an invoice contains 
incorrect fee information, You shall send a written notice to Us within 
fifteen (15) days of receipt of invoice ("</span><span style="font-family:Times-Bold; font-weight:bold">Dispute Period</span><span style="font-family:Times-Roman">")
providing a reasonably detailed explanation of the nature of the 
dispute, which explanation shall set forth the dollar amounts withheld 
and the reasons for withholding such amounts. If We do not respond to 
the notice within thirty (30) days, Your determination as to the correct
fee information set forth in the written notice shall be final. If You 
do not dispute the applicable invoice during the Dispute Period, any 
such dispute shall be deemed waived. You remain obligated to pay Us for 
all portions of the applicable invoice that are not under reasonable and
good faith dispute. The Parties shall work together expeditiously and 
in good faith to resolve all fee disputes. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">4.6</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman">ANY
CONTRIBUTION GROWER ENTERS INTO THE HOSTED SERVICE, AND ANY 
CUSTOMIZATIONS MADE TO THE HOSTED SERVICE OR DATA BY OR FOR GROWER, 
DURING GROWER’S FREE TRIAL WILL BE PERMANENTLY LOST UNLESS GROWER 
PURCHASES A SUBSCRIPTION TO THE SAME HOSTED SERVICE AS THOSE COVERED BY 
THE TRIAL, OR EXPORTS SUCH DATA, BEFORE THE END OF THE TRIAL PERIOD. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">NOTWITHSTANDING
THE “REPRESENTATIONS AND WARRANTIES" SECTION AND “INDEMNIFICATION BY 
GRODAN” SECTION BELOW, DURING THE FREE TRIAL THE HOSTED SERVICE AND DATA
ARE PROVIDED “AS-IS” WITHOUT ANY WARRANTY AND GRODAN SHALL HAVE NO 
INDEMNIFICATION OBLIGATIONS NOR LIABILITY OF ANY TYPE WITH RESPECT TO 
THE HOSTED SERVICE FOR THE FREE TRIAL PERIOD UNLESS SUCH EXCLUSION OF 
LIABILITY IS NOT ENFORCEABLE UNDER APPLICABLE LAW IN WHICH CASE GRODAN’S
LIABILITY WITH RESPECT TO THE HOSTED SERVICE AND DATA PROVIDED DURING 
THE FREE TRIAL SHALL NOT EXCEED $1,000.00. WITHOUT LIMITING THE 
FOREGOING, GRODAN AND ITS AFFILIATES AND ITS LICENSORS DO NOT REPRESENT 
OR WARRANT TO GROWER THAT: (A) GROWER’S USE OF THE HOSTED SERVICE DURING
THE FREE TRIAL PERIOD WILL MEET GROWER’S REQUIREMENTS, (B) GROWER’S USE
OF THE HOSTED SERVICE DURING THE FREE TRIAL PERIOD WILL BE 
UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, AND (C) USAGE DATA 
PROVIDED DURING THE FREE TRIAL PERIOD WILL BE ACCURATE. NOTWITHSTANDING 
ANYTHING TO THE CONTRARY IN THE “LIABILITY” SECTION BELOW, GROWER SHALL 
BE FULLY LIABLE UNDER THIS AGREEMENT TO GRODAN AND ITS AFFILIATES FOR 
ANY DAMAGES ARISING OUT OF GROWER’S USE OF THE HOSTED SERVICE DURING THE
FREE TRIAL PERIOD, ANY BREACH BY GROWER OF THIS AGREEMENT AND ANY OF 
GROWER’S INDEMNIFICATION OBLIGATIONS HEREUNDER.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">5.</span><span style="width:27pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold">PRIVACY </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:TimesNewRomanPSMT">5.1</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:TimesNewRomanPSMT; text-decoration:underline">Our Responsibilities</span><span style="font-family:TimesNewRomanPSMT">.
We will routinely backup (not less frequently than once-per-day) all 
Contributions and use industry standard security measures to maintain 
Your Users’ login information (e.g., User IDs and passwords) for the 
Hosted Service in confidence. All storage, backup, and archival media, 
containing Contributions shall be (a) physically stored in a secure area
(b) logically separated from any other customer’s data and (c) 
protected by industry standard encryption methods. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:TimesNewRomanPSMT">5.2</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:TimesNewRomanPSMT; text-decoration:underline">Personal Data</span><span style="font-family:TimesNewRomanPSMT">. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:TimesNewRomanPSMT">5.2.1</span><span style="width:12pt; display:inline-block">&nbsp;</span><span style="font-family:TimesNewRomanPSMT">Where We process personal data within the scope of this Agreement, We shall do so in accordance with this Terms of Use (</span><span style="font-family:TimesNewRomanPSMT; text-decoration:underline">Schedule 2</span><span style="font-family:TimesNewRomanPSMT">) and the Privacy Statement (</span><span style="font-family:TimesNewRomanPSMT; text-decoration:underline">Schedule 3</span><span style="font-family:TimesNewRomanPSMT">), in particular, We will: </span></p><p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-108pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:TimesNewRomanPSMT">•</span><span style="font:7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style="font-family:TimesNewRomanPSMT">take appropriate 
technical and organisational measures against the unauthorized or 
unlawful processing of personal data and against actual loss or 
destruction of, or damage to, personal data;</span></p><p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-108pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:TimesNewRomanPSMT">•</span><span style="font:7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style="font-family:TimesNewRomanPSMT">process personal data only in accordance with the scope of this Agreement, including the Schedules;</span></p><p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-108pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:TimesNewRomanPSMT">•</span><span style="font:7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style="font-family:TimesNewRomanPSMT">refrain from 
disclosing personal data to any third party except for the third parties
engaged with regards to the performance of this Agreement and the 
further development of the precision growing platform. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:TimesNewRomanPSMT">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">5.2.2</span><span style="width:12pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman">In addition to the Privacy Statement</span><span style="font-family:Times-Roman">, </span><span style="font-family:Times-Roman">We
may use Your personal information, for example personal information 
provided by You through the Hosted Service, by using the Hosted Service,
or in any other way, personal information about Your use of the Hosted 
Service (e.g., log information) and technical information about Your 
device (e.g., a mobile device or computer). Such personal information 
may be collected and used to ensure proper performance of the Hosted 
Service, to provide the Hosted Service and Data to You, to improve the 
Hosted Service and/or the Professional Services, to improve the Hosted 
Service by means of machine learning, and to contact You or provide You 
with relevant information with regard to (Your use of) the Hosted 
Service, Data and/or the Professional Services. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">5.2.3
In case and insofar as We process any Your personal information, such 
information will be treated and processed in accordance with applicable 
personal information protections. By using the Hosted Service or 
providing us with any personal information through the Hosted Service, 
You agree and consent to the collection, use, disclosure and processing 
of such information in accordance with these Terms of Use, Our Privacy 
Statement</span><span style="font-family:Times-Roman">, </span><span style="font-family:Times-Roman">and applicable personal information protections by us and Our Affiliates.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">5.2.4
Any personal information provided by You through the Hosted Service 
will be used, together with personal information of other Users, to 
improve the Hosted Service. Any personal information used will be 
anonymized and will therefore not be regarded as personal information 
under virtue of the personal information protection legislation. The 
personal information may be transferred to countries outside of the US, 
including the EU, Canada and others in order to provide access to the 
Hosted Service. While Your personal information is in a jurisdiction it 
may be accessed by the courts, law enforcement and national security 
authorities of that jurisdiction in accordance with the laws applicable 
in that jurisdiction. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">6.</span><span style="width:27pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold">Compliance</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">6.1</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Use.</span><span style="font-family:Times-Roman">
As the User, You represent, warrant and agree that You shall only use 
the Hosted Service, Data and Professional Services for the purpose for 
which Grodan provides the Limited License.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">6.2</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Cooperation.</span><span style="font-family:Times-Roman">
At the first request of Grodan, the User shall provide all necessary 
cooperation, facilities and information for an inspection and/or audit 
by Grodan or any third party engaged by Grodan to verify that the User 
acts in full conformity with this Section 6. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">6.3</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Remedies.</span><span style="font-family:Times-Roman">
In case of any violation, Grodan is entitled to immediately terminate 
the business relationship with the User including any agreements that 
may have been entered into and the use of the Hosted Service (including 
these Terms of Use). Grodan is also entitled to cancel any order placed,
even if it has been expressly accepted by Grodan. The User will not be 
entitled to any compensation from Grodan in case of termination.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">7.</span><span style="width:27pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold">Representations and Warranties</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">7.1 </span><span style="width:18pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Mutual Warranties</span><span style="font-family:Times-Roman">.
Each Party represents and warrants that (a) it has the legal power to 
enter into this Agreement, and (b) it will not transmit to the other 
Party any Malicious Code. </span></p><p style="margin-top:0pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">7.2</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Disclaimers.</span><span style="font-family:Times-Roman">
YOUR USE OF THE HOSTED SERVICE, ITS LIMITED LICENSE, AND ANY SERVICES 
OR MATERIALS OBTAINED THROUGH OR RELATED TO THE HOSTED SERVICE, 
INCLUDING BUT NOT LIMITED TO DATA, IS AT YOUR OWN RISK. EXCEPT AS 
EXPRESSLY SET FORTH IN THE "REPRESENTATIONS AND WARRANTIES" SECTION, THE
HOSTED SERVICE, ITS LIMITED LICENSE, AND ANY SERVICES OR MATERIALS 
OBTAINED THROUGH OR RELATED TO THE HOSTED SERVICE, INCLUDING BUT NOT 
LIMITED TO DATA, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, 
WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER 
GRODAN NOR ANY PERSON ASSOCIATED WITH GRODAN MAKES ANY WARRANTY OR 
REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, 
QUALITY, ACCURACY, OR AVAILABILITY OF THE HOSTED SERVICE OR DATA. 
WITHOUT LIMITING THE FOREGOING, NEITHER GRODAN NOR ANYONE ASSOCIATED 
WITH GRODAN REPRESENTS OR WARRANTS THAT THE HOSTED SERVICE, ITS LIMITED 
LICENSE, OR ANY SERVICES OR MATERIALS OBTAINED THROUGH THE HOSTED 
SERVICE, INCLUDING BUT NOT LIMITED TO DATA, WILL BE ACCURATE, RELIABLE, 
ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE 
HOSTED SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT 
THE HOSTED SERVICE OR ANY LIMITED LICENSES OR MATERIALS OBTAINED THROUGH
THE HOSTED SERVICE, INCLUDING BUT NOT LIMITED TO DATA, WILL OTHERWISE 
MEET YOUR NEEDS OR EXPECTATIONS. </span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">TO
THE FULLEST EXTENT PROVIDED BY LAW, GRODAN HEREBY DISCLAIMS ALL 
WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR 
OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF QUALITY, 
MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</span><span style="font-family:Times-Roman"> </span></p><p style="margin-top:12pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">8.</span><span style="width:27pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold">Liability</span></p><p style="margin-top:0pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">IN
NO EVENT SHALL WE BE LIABLE FOR ANY LOST PROFITS OR FOR INDIRECT, 
SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED 
AND WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, 
ARISING OUT OF OR IN ANY WAY CONNECTED WITH THIS AGREEMENT, THE HOTED 
SERVICE OR SERVICES. EVEN IF WE ARE ADVISED OF THE POSSIBILITY OF SUCH 
DAMAGES.</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">IN
ALL EVENTS, OUR AGGREGATE LIABILITY TO YOU FOR CLAIMS RELATING TO THIS 
AGREEMENT OR THE HOSTED SERVICE, WHETHER FOR BREACH OF CONTRACT OR IN 
TORT OR UNDER ANY OTHER THEORY OF LIABILITY, SHALL BE LIMITED TO THE 
AMOUNT ACTUALLY PAID BY YOU TO US HEREUNDER DURING THE TWELVE (12) MONTH
PERIOD IMMEDIATELY BEFORE THE CLAIM WHICH GAVE RISE TO THE LIABILITY. 
THE LIMITATIONS SET FORTH IN THIS CLAUSE SHALL APPLY NOTWITHSTANDING THE
FAILURE OF THE ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. THE FOREGOING 
DISCLIMAER WILL NOT APPLY TO THE EXTENT PROHIBITED BY LAW.</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">9.</span><span style="width:27pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold">Force Majeure Event. </span><span style="font-family:Times-Roman">We
will not be liable or responsible for any inability to use the Hosted 
Service that is caused by events outside Our reasonable control (a "</span><span style="font-family:Times-Bold; font-weight:bold">Force Majeure Event</span><span style="font-family:Times-Roman">").
A Force Majeure Event includes any act, event, non-happening, omission 
or accident beyond Our reasonable control and includes in particular 
(without limitation): (i) impossibility of the use of public or private 
telecommunications or electricity networks; (ii) legislation, 
regulations or (export) restrictions of any government; (iii) 
cyberterrorism or other cyber-attacks, security incidents, intentional 
or otherwise corrupting or loss of data and (iii) the non-delivery or 
late delivery of products or services to Grodan by third parties engaged
by Grodan. Accordingly, You shall not have the right to terminate this 
Agreement for cause on account of a failure to perform Our obligations 
hereunder during the period of such excused performance pursuant to the 
foregoing. </span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">10.</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold">Intellectual Property Rights.</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">10.1</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">General</span><span style="font-family:Times-Roman">.
All right, title, and interest in and to the Hosted Service, Aggregate 
Data, Our Confidential Information, Data and Documentation, including, 
without limitation, contents, features, and functionality (including but
not limited to all information, software, text, displays, images, 
video, and audio, and the design, selection, and arrangement thereof), 
and all modifications, enhancements, derivative works and intellectual 
property rights thereto shall belong solely to Grodan, its licensors, or
other providers of such material and are protected by Danish, United 
States of American, Canadian, EU and international copyright, trademark,
patent, trade secret, and other intellectual property or proprietary 
rights laws. </span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">10.2</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Ownership of Contributions</span><span style="font-family:Times-Roman">.
You exclusively own all right, title and interest in and to 
Contributions. In the event of termination or expiration of this 
Agreement or any applicable Order Form, and if legally permissible and 
requested by You within thirty (30) days of such termination or 
expiration, We will (a) return to You Contributions or (b) destroy or 
permanently erase Contributions. After such 30-day period. We will have 
no other further obligation to maintain or provide access to 
Contributions. </span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">10.3</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">License to Us</span><span style="font-family:Times-Roman">.
You hereby grant, and shall grant, to Us a worldwide, royalty-free, 
non-exclusive, perpetual right to access, use, reproduce and prepare 
derivative works based on Contributions for the purpose of providing the
Hosted Services and Professional Services hereunder and to improve or 
enhance such Hosted Services and Professional Services. This license 
includes use of Contributions to compile, use and disclose Aggregate 
Data as set forth in Section 3.3.2. You shall be solely responsible for 
ensuring that You have obtained all necessary third party consents and 
made all required disclosures to enable the foregoing grant.</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">10.4</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Suggestions</span><span style="font-family:Times-Roman">.
You grant to Grodan and its Affiliates a worldwide, perpetual, 
irrevocable, royalty- free license to use and incorporate into its 
services any suggestion, enhancement request, recommendation, correction
or other feedback provided by You or Users relating to the operation of
Grodan or its Affiliates’ services.</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">10.5</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Trademarks</span><span style="font-family:Times-Roman">.&nbsp; The 'Roxul' and 'Grodan' names; the Affiliate entities including but not limited to 'Grodan,' 'e-Gro,' 'ROCKWOOL,' and</span><span style="font-family:Times-Roman"> 'Roxul' and all related names, logos, product and service names, designs, and slogans are</span><span style="font-family:Times-Roman">
trademarks of Grodan or its Affiliates or licensors. You shall not use 
such marks without Grodan's prior written permission. All goodwill based
upon such use shall inure to the benefit of Grodan.&nbsp;&nbsp; No 
other rights to such trademarks are granted to You, and You acknowledge 
that You shall not gain any proprietary interest in Grodan's or its 
Affiliates or licensors trademarks. All other names, logos, product and 
service names, designs, and slogans on the Hosted Service are the 
trademarks of their respective owners.</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">11.</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold">Confidentiality </span></p><p style="margin-top:12pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">11.1</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Definition of Confidential Information</span><span style="font-family:Times-Roman">. As used herein, “</span><span style="font-family:Times-Bold; font-weight:bold">Confidential Information</span><span style="font-family:Times-Roman">” means all confidential and proprietary information of a Party (“</span><span style="font-family:Times-Bold; font-weight:bold">Disclosing Party</span><span style="font-family:Times-Roman">”) disclosed to the other Party (“</span><span style="font-family:Times-Bold; font-weight:bold">Receiving Party</span><span style="font-family:Times-Roman">”),
whether orally or in writing, that is designated as confidential or 
that reasonably should be understood to be confidential given the nature
of the information and the circumstances of disclosure, including the 
terms and conditions of this Agreement. Grodan's Confidential 
Information shall include, without limitation, pricing and other terms 
reflected in all Order Forms, the Hosted Service, Data, Professional 
Services, business and marketing plans, technology, financial and 
technical information, product designs, and business processes. The 
result of the processing of information, calculations, advice and other 
results carried out on the basis of information supplied by the User are
strictly confidential to Grodan and shall be deemed Grodan's 
Confidential Information. You may not in any event disclose all or part 
of the method, results of the calculations, advice and other results, 
allow it to be inspected by third parties, publish it or grant 
permission for publication without the written permission of Grodan. 
Your Confidential Information shall include Contributions. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">11.2</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Exclusions.</span><span style="font-family:Times-Roman">
Confidential Information shall not include any information that: (i) is
or becomes generally known to the public without breach of any 
obligation owed to the Disclosing Party; (ii) was known to the Receiving
Party prior to its disclosure by the Disclosing Party without breach of
any obligation owed to the Disclosing Party; (iii) was independently 
developed by the Receiving Party without breach of any obligation owed 
to the Disclosing Party; or (iv) is received from a third party without 
breach of any obligation owed to the Disclosing Party. The Receiving 
Party shall not disclose or use any Confidential Information of the 
Disclosing Party for any purpose outside the scope of this Agreement, 
except with the Disclosing Party's prior written permission.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">11.3</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Standard of Care.</span><span style="font-family:Times-Roman">
Each Party shall protect the confidentiality of the Confidential 
Information of the other Party in the same manner that it protects the 
confidentiality of its own proprietary and confidential information of 
like kind (but in no event using less than reasonable care). Grodan 
shall notify Grower promptly in the event a security breach related to 
Contributions. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">11.4</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Compelled Disclosure.</span><span style="font-family:Times-Roman">
If the Receiving Party is compelled by law to disclose Confidential 
Information of the Disclosing Party, it shall provide the Disclosing 
Party with prior notice of such compelled disclosure (to the extent 
legally permitted) and reasonable assistance, at Disclosing Party's 
cost, if the Disclosing Party wishes to contest the disclosure. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">1.5</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Equitable Relief.</span><span style="font-family:Times-Roman">
If the Receiving Party discloses or uses (or threatens to disclose or 
use) any Confidential Information of the Disclosing Party in breach of 
confidentiality protections hereunder, the Disclosing Party shall have 
the right, in addition to any other remedies available to it, to seek 
injunctive relief to enjoin such acts, it being specifically 
acknowledged by the Parties that any other available remedies are 
inadequate.</span></p><p style="margin-top:0pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">12.</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold">Mutual Indemnification</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:ArialMT">12.1</span><span style="width:12.64pt; display:inline-block">&nbsp;</span><span style="font-family:ArialMT; text-decoration:underline">Indemnification by Grodan</span><span style="font-family:ArialMT">. Subject to this Agreement, We shall defend You against any loss, damage or costs</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:ArialMT"> (including reasonable attorneys' fees) incurred in connection with claims, demands, suits, or proceedings (“</span><span style="font-family:Times-Bold; font-weight:bold">Claims</span><span style="font-family:ArialMT">”)
made or brought against You by a third party alleging that the use of 
the Hosted Service as contemplated hereunder infringes the intellectual 
property rights of a third party&nbsp; and will indemnify You from any 
damages, attorney fees and costs finally awarded against You as a result
of, or for amounts paid by You under a settlement approved by Us in 
writing of, a Claim; provided, that You (a) promptly give written notice
of the Claim to Us; (b) give Us sole control of the defense and 
settlement of the Claim (provided that We may not settle or defend any 
Claim unless We unconditionally release You of all liability); and (c) 
provide to Us, at Our cost, all reasonable assistance. In the event We 
are required to indemnify You in accordance with this Section 12.1, or 
if We reasonably believe the Hosted Service may infringe a third party's
intellectual property rights, then We may at Our sole option and 
expense (i) modify the Hosted Service so that it no longer infringes 
without materially adversely affecting its operation, or materially 
altering its capacity or performance, (ii) obtain a license for Your 
continued use of the Hosted Service, and/or (iii) remove the infringing 
component from the Hosted Service without materially adversely affecting
its operation, or materially altering its capacity or performance. If, 
despite Our commercially reasonable efforts to do so, the foregoing 
options are not reasonably practicable, then We may terminate this 
Agreement, in which case We shall issue to You a refund of all prepaid 
fees covering the remainder of the then-current Subscription Term(s) 
from the point in time when You were unable to make use of the Hosted 
Service due to the third party infringement claim. Furthermore, We shall
have no liability for any Claim under Section 12.1 to the extent such 
liability is the result of (i) modifications to the Hosted Service by 
anyone other than Us or Our agents (provided that We shall not be liable
if We or Our agents made the modifications using requirements, 
documents, written specifications or other written materials submitted 
by You or Your agents or representatives); (ii) the use or combination 
of the Hosted Service with any other item not provided by Us where in 
the absence of such use or combination, the Hosted Service alone would 
not have given rise to the Claim; or (iii) Your continued use of an 
infringing version of the Hosted Service when the then current version 
of the Hosted Service has been modified to be non-infringing.</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:ArialMT">12.2</span><span style="width:12.64pt; display:inline-block">&nbsp;</span><span style="font-family:ArialMT; text-decoration:underline">Indemnification by You</span><span style="font-family:ArialMT">. </span><span style="font-family:Times-Roman">You
agree to defend, indemnify, and hold harmless Grodan, its Affiliates, 
licensors, and service providers, and its and their respective officers,
directors, employees, contractors, agents, licensors, suppliers, 
successors, and assigns from and against any Claims arising out of or 
relating to Your violation of the Agreement or Your use of the Hosted 
Service, including, but not limited to Your User Contributions, any use 
of the Hosted Service’s content, Professional Services, Data and Limited
Licenses other than as expressly authorized in the Agreement or Your 
use of any information obtained from the Hosted Service. </span><span style="font-family:ArialMT">We
will (a) promptly give written notice of the Claim to You; (b) give You
sole control of the defense and settlement of the Claim (provided that 
You may not settle or defend any Claim unless it unconditionally 
releases Us of all liability); and (c) provide to You, at Your cost, all
reasonable assistance.</span><span style="font-family:Times-Roman"> </span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">12.3</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:ArialMT; text-decoration:underline">Exclusive Remedy</span><span style="font-family:ArialMT">.
This “Mutual Indemnification” section states the indemnifying Party’s 
sole liability to, and the indemnified Party’s exclusive remedy against,
the other Party for any type of Claim described in this section.</span></p><p style="margin-top:12pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">13.</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold">Term, Expiration and Termination. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Bold; font-weight:bold">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:ArialMT">13.1</span><span style="width:12.64pt; display:inline-block">&nbsp;</span><span style="font-family:ArialMT; text-decoration:underline">Term</span><span style="font-family:ArialMT">.
The term of this Agreement commences on the Effective Date and 
continues until all Subscription Terms expire or are otherwise 
terminated. The Hosted Service may automatically deactivate and become 
non-operational at the end of the Subscription Term, and You may not 
access the Hosted Service thereafter unless You renew the applicable 
Order Form.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:ArialMT">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">13.2</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Renewal</span><span style="font-family:Times-Roman">.
Subscription Terms shall automatically renew for additional periods of 
one (1) year unless either Party gives the other notice of non-renewal 
at least thirty (30) days prior to the end of the relevant Subscription 
Term. In the event of an automatic renewal, per-unit subscription 
pricing for each renewal term will increase by no more than 10% as 
compared to the prior term unless We provide You with notice of 
different pricing at least 60 days prior to the applicable renewal, and 
price increases will be reflected on an invoice. A new Order Form is not
required unless additional or different services are ordered. </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">13.3</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Termination</span><span style="font-family:Times-Roman">.
A Party may terminate this Agreement for cause (a) upon thirty (30) 
days prior written notice to the other Party of a material breach by the
other Party if such breach remains uncured at the expiration of such 
period; or (b) as otherwise provided herein. Without prejudice to any 
other rights, either Party shall be entitled to terminate this Agreement
forthwith and with immediate effect if the other Party: (i) applies 
for, consents to, or becomes subject to, the appointment of a receiver, 
manager or administrative receiver over any of its property or assets; 
(ii) becomes unable, or admits in writing its inability, to pay its 
debts as they fall due; (iii) makes any voluntary arrangement with its 
creditors or becomes subject to an administration order or an 
administrator is appointed or documents are filed at court for the 
appointment of an administrator of that other Party, or notice of 
intention to appoint an administrator is given by that other Party;(iv) 
goes into liquidation or an order is made or a resolution is passed for 
the winding-up of that other Party; or (v) ceases or threatens to cease 
to carry on business.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">13.4</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Termination for</span><span style="font-family:Times-Roman"> </span><span style="font-family:Times-Roman; text-decoration:underline">Nonpayment.</span><span style="font-family:Times-Roman">
Without prejudice to any other rights, Grodan shall be entitled to 
terminate this Agreement forthwith and with immediate effect: If Grower 
fails to pay any Grodan invoice or amount due to Grodan in time; 
provided Grodan will not excerice this right unless and until it has 
first suspended Grower's access to the Hosted Service as set forth in 
Section 4.4 (Suspension). </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">13.5</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Effects of Termination</span><span style="font-family:Times-Roman">.
Upon any expiration or termination of this Agreement, and upon 
expiration of the Subscription Term if You do not renew in accordance 
with Section 13.2, the rights and licenses granted hereunder will 
automatically terminate, and You may not continue to use the Hosted 
Service. If the Agreement is terminated based upon Our uncured material 
breach, We shall refund to You any prepaid fees covering the remainder 
of Your Subscription Term after the date of such termination. If the 
Agreement is terminated based on Your uncured material breach, You shall
pay any unpaid fees covering the remainder of the Subscription Term(s) 
of all Order Forms after the effective date of such termination. 
Termination of this Agreement shall not limit the Parties from pursuing 
any other remedies available, including injunctive relief.</span></p><p style="margin-top:0pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">13.6</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Breach of</span><span style="font-family:Times-Roman"> </span><span style="font-family:Times-Roman; text-decoration:underline">the Agreement</span><span style="font-family:Times-Roman">.
If You print, copy, modify, download, or otherwise use or provide any 
other person with access to any part of the Hosted Service in breach of 
the Agreement, Your right to use the Hosted Service will stop 
immediately and You must, at Our option, return or destroy any copies of
the materials You have made. No right, title, or interest in or to the 
Hosted Service, Data or any content on the Hosted Service is transferred
to You, and all rights not expressly granted are reserved by Grodan. 
Any use of the</span><span style="font-family:Times-Roman"> Hosted 
Service not expressly permitted by the Agreement is a breach of the 
Agreement and may violate copyright, trademark, and other laws. </span><span style="font-family:Times-Roman">The
User will ensure that its use of the Hosted Service will in no way 
prejudice any rights and/or the good name and reputation of Grodan.</span></p><p style="margin-top:12pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">13.7</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Survival.</span><span style="font-family:Times-Roman">
The Parties’ rights and obligations under Sections 3.3 (User 
Contributions), 4 (Fees), 8 (Liability), 10 (Intellectual Property 
Rights), 11 (Confidentiality), 12 (Mutual Indemnification), 13 (Term, 
Expiration and Temination), and 14 (Miscellaneous) shall survive the 
termination of this Agreement for any reason.</span></p><p style="margin-top:0pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">14.</span><span style="width:21pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Bold; font-weight:bold">Miscellaneous</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">14.1&nbsp; </span><span style="width:9pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Notices</span><span style="font-family:Times-Roman">.
All notices given by You to Us must be given to Grodan via registered 
letter to: ROXUL Inc., 8024 Esquesing Line, Milton, Ontario, L9T 
6W3.&nbsp; We may give notice to You at either the e-mail or postal 
address set forth in this Agreement, in the applicable Order Form or 
that You provide to Us when registering through the Hosted Service. 
Notice will be deemed received and properly served immediately 24</span><span style="font-family:Times-Roman">&nbsp;</span><span style="font-family:Times-Roman">hours (working day) after an e-mail is sent, immediately after service, or three</span><span style="font-family:Times-Roman">&nbsp;</span><span style="font-family:Times-Roman">working
days after the date of posting of any letter. In proving the service of
any notice, it will be sufficient to prove service with the receipt of a
registered letter and, in the case of an e-mail, that such e-mail was 
sent to the specified e-mail address of the addressee.</span></p><p style="margin-top:12pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">14.2 </span><span style="width:12pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Transfer and Assignment</span><span style="font-family:Times-Roman">.
You may not transfer, assign, charge, sub-contract or otherwise dispose
of any of Your rights or obligations arising under the Agreement, 
without the prior written consent of Grodan. Notwithstanding the 
foregoing, You may assign this Agreement in its entirety (including all 
Order Forms), without Our consent, to Your Affiliates or in connection 
with a merger, acquisition, corporate reorganization, or sale of all or 
substantially all of Your assets not involving a direct competitor of 
Grodan or its Affiliates. Subject to the foregoing, this Agreement shall
bind and inure to the benefit of the Parties, their respective 
successors and permitted assigns. We may at any time transfer, assign, 
charge, sub-contract or otherwise dispose of any of Our rights or 
obligations arising under Agreement.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">14.3</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Waiver</span><span style="font-family:Times-Roman">.
If We fail to insist upon strict performance of any of Your obligations
under any of Agreement, or if We fail to exercise any of the rights or 
remedies to which We are entitled under Agreement, this shall not 
constitute a waiver of such rights or remedies and shall not relieve You
from compliance with such obligations. A waiver by Us of any default 
shall not constitute a waiver of any subsequent default. No waiver by Us
of any of Agreement shall be effective unless it is expressly stated to
be a waiver and is communicated to You in writing in accordance with 
the Notice provisions contained in Agreement.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">14.4</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Severability.</span><span style="font-family:Times-Roman">
If any term or provision of this agreement is invalid, illegal or 
unenforceable in any jurisdiction, such invalidity, illegality or 
unenforceability shall not affect any other term or provision of this 
agreement or invalidate or render unenforceable such term or provision 
in any other jurisdiction. Upon a determination that any term or other 
provision is invalid, illegal or unenforceable, the Parties shall 
negotiate in good faith to modify this agreement so as to affect the 
original intention of the Parties as closely as possible in a mutually 
acceptable manner.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">14.5</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Law and Jurisdiction.</span><span style="font-family:Times-Roman">
This Agreement shall be governed by and construed in accordance with 
the laws of the Province of Ontario and the laws of Canada applicable 
therein, except to the extent local mandatory laws apply. All disputes 
arising out of or in connection with these Terms of Use shall be subject
to the exclusive jurisdiction of the courts located in the Province of 
Ontario. Notwithstanding the exclusive jurisdiction of Ontario, Grodan, 
at its sole option, will also and at all times be entitled to submit a 
dispute or claim to the competent court for the place where the User has
his residence or registered or actual place of business. If You or 
Grodan commences any action or proceeding against the other to enforce 
Agreement, or to recover damages or obtain injunctive or other relief 
resulting from the alleged breach of any provision of this Agreement, 
the prevailing Party shall be entitled to recover all reasonable costs 
and expenses, including without limitation reasonable attorneys' fees.</span></p><p style="margin-top:12pt; margin-bottom:12pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">ANY
CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO 
AGREEMENT OR THE HOSTED SERVICE MUST BE COMMENCED WITHIN ONE (1) YEAR 
AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR 
CLAIM IS PERMANENTLY BARRED. </span></p><p style="margin-top:12pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">14.6</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Your Reference and Case Study</span><span style="font-family:Times-Roman">.&nbsp;
We may disclose that You are a customer. We may announce to the press 
that You have purchased the Hosted Service.&nbsp; If a press release is 
created, You may request the opportunity to approve the release. Within 
thirty (30) days of Our written request, You will speak with an industry
analyst and/or participate in an industry analyst survey discussing 
Your selection criteria for choosing Grodan. We may announce to the 
press that You have completed the implementation of Hosted 
Service.&nbsp; </span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">14.7</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Independent contractors.</span><span style="font-family:Times-Roman">&nbsp;
The Parties are and intend to remain independent contractors. Nothing 
in this agreement shall be construed as an agency, joint venture or 
partnership between the Parties.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">14.8</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Entire Agreement.</span><span style="font-family:Times-Roman">
The Agreement constitutes the entire agreement between You and Grodan 
and supersedes any prior understanding or agreements, oral or written. 
All captions, titles, headings, and divisions in these Terms of Use are 
for purposes of convenience and reference only, and shall not be 
construed to limit or affect the interpretation of the Terms of Use. 
Notwithstanding any language to the contrary therein, no terms or 
conditions stated in Your purchase order or in any other ordering 
documentation (excluding Order Forms) shall be incorporated into or form
any part of the Agreement, and all such terms shall be null and void. 
This Agreement is intended for the sole and exclusive benefit of the 
Parties and is not intended to benefit any third party. Only the Parties
to this Agreement may enforce it.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">14.9</span><span style="width:15pt; display:inline-block">&nbsp;</span><span style="font-family:Times-Roman; text-decoration:underline">Counterparts.</span><span style="font-family:Times-Roman">
This Agreement may be entered into in any number of counterparts, all 
of which taken together shall constitute one and the same instrument. 
Any Party may enter into this Agreement by signing any such counterpart.
The exchange of a fully executed Agreement (in counterparts or 
otherwise) by email, DocuSign or fax shall be sufficient to bind the 
Parties to the terms and conditions of this Agreement.</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt"><span style="font-family:Times-Roman">&nbsp;</span></p></div>`
